import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import { slugify } from '../utils/Misc';
const faqs = [
  {
    title: 'What is an Event Organiser? what they do? ',
    content:
      'An organiser will host a tournament, they will have a system to control and manage the tournament, they can hire streamers, on this panel E-Warz will also have a sponsor section as well, which can show interest in any tournament, this if both parties agree sponsors can financially support any tournament.',
  },
  {
    title: 'How do I find or join a tournament?',
    content:
      'The players or team leader or manager can create the team in his platform, they can look for any tournament where the team can take part in, once they have signed up for the tournament, the organisers can schedule the matches and the information will be passed to the member who will be part of that match.',
  },
  {
    title: 'Who is a Team Manger?',
    content:
      'A team manager/ Team leader will have a overview on all tournaments they have took part in, they will see how they are doing as team, what are the future tournaments they can take party in, also by seeming the recent matches they can make important discussions on the team and the are which needs improvement.',
  },
  {
    title: 'What is a Commentator and what do they do?',
    content:
      'A commentator will be the online state host in other words this person will be sports caster who provides commentary during a live tournament event, commentator will usually work either the streamer in synchronisation to deliver the best experience to the audience.',
  },
];
const Pricing = () => {
  return (
    <Layout>
      <Helmet title="Pricing | E-Warz Tournaments" />
      <section class="clean-block clean-pricing bg-dark-alt pb-5">
        <div class="container">
          <div class="block-heading">
            <h2 class="text-info">Pricing for Players</h2>
            <h5 className="text-white mt-5">
              Our platform is completely <b>FREE</b> for all players. <br />
              But in order to play paid tournaments, they may have to pay some amount depends upon
              the entry fee.
            </h5>
          </div>
          <div class="block-heading">
            <h2 class="text-info">Pricing for Organisers</h2>
            <p className="text-white">We offer attractive pricing for our Event Organisers.</p>
          </div>
          <div class="row justify-content-center text-white">
            <div class="col-md-5 col-lg-4">
              <div class="clean-pricing-item card">
                <div className="card-body">
                  <div class="heading">
                    <h3>STARTER</h3>
                  </div>
                  <p>All the basic features to Starting hosting basic tournaments.</p>
                  <div class="features">
                    <h4>
                      <span class="feature">2 Tournaments Per Month</span>
                    </h4>
                    <h4>
                      <span class="feature">Max 20 Teams per tournament</span>
                    </h4>
                    <h4>
                      <span class="feature">Single Elemination Tournaments only</span>
                    </h4>
                    <h4>
                      <span class="feature">Basic E-Mail and chat support</span>
                    </h4>
                  </div>
                  {/* <div class="price">
                    <h4>FREE</h4>
                  </div> */}
                  <button class="btn btn-outline-primary btn-block" type="button">
                    FREE
                  </button>
                  {/* <button class="btn btn-outline-primary btn-block" type="button">
                    Register for FREE
                  </button> */}
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-4">
              <div class="clean-pricing-item card">
                <div className="card-body">
                  {/* <div class="ribbon">
                    <span>Best Value</span>
                  </div> */}
                  <div class="heading">
                    <h3>Pro</h3>
                  </div>
                  <p>A Complete solution to host all type of small to large scale tournaments.</p>
                  <div class="features">
                    <h4>
                      <span class="feature">Unlimited Tournaments Per Month</span>
                    </h4>
                    <h4>
                      <span class="feature">Max 250 Teams per tournament</span>
                    </h4>
                    <h4>
                      <span class="feature">All type of Tournaments</span>
                    </h4>
                    <h4>
                      <span class="feature">Priority Phone support</span>
                    </h4>
                  </div>
                  <button class="btn btn-primary btn-block" type="button">
                    CONTACT US
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Pricing;
